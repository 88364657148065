:root {
  --primary: #86b4e0;
}

::-webkit-scrollbar {
  width: 0;
}

/* Hide the horizontal scrollbar */

::-webkit-scrollbar {
  height: 0;
}

.bi {
  padding: 0;
}

.body {
  font-family: "Montserrat", sans-serif;
}

.piik-text-warning {
  font-size: 12px;
}

.piik-pointer {
  cursor: pointer;
}

.piik-pointer-not-allowed {
  cursor: not-allowed;
}

.piik-nav-bar {
  background-color: #0d6dfd63;
}

.piik-text-dashboard {
  font-size: 0.8rem;
}

.piik-text-header {
  font-size: 2rem;
}

.piik-text-icon {
  font-size: 0.6rem;
}

.piik-text-alert {
  font-size: 0.8rem;
}

.piik-text-content-header {
  font-size: 1.2rem;
}

.piik-card-login {
  max-width: 500px;
  min-width: 300px;
}

.piik-home-container {
  display: flex;
}

.piik-home-sidebar {
  max-width: 5rem;
  background-color: #f0f0f0;
  height: 100vh;
  flex: 0 0 5%;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.piik-home-sidebar-active {
  max-width: 3rem;
  background-color: #f0f0f0;
  height: 100vh;
  flex: 0 0 15%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: scroll;
  transition: max-width 1s ease;
}

.piik-home-sidebar-deactive {
  max-width: 15rem;
  background-color: #f0f0f0;
  height: 100vh;
  flex: 0 0 15%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  transition: max-width 1s ease;
}

.piik-home-main-content {
  flex: 1;
  background-color: #eeeeee;
}

.piik-home-main-content-body {
  margin: auto;
  max-width: 1200px;
}

.piik-card {
  height: 80vh;
}

@media (min-width: 1600px) {
  .piik-home-main-content-body {
    max-width: 1600px;
  }
}

.piik-card-icon {
  width: 10rem;
  margin-left: -1rem;
  box-shadow: 8px 0 8px -4px rgba(0, 0, 0, 0.5);
  background-color: #0d6efd;
  cursor: pointer;
}

.piik-card-icon:hover {
  width: 10rem;
  box-shadow: 8px 0 8px -4px rgba(0, 0, 0, 0.5);
  background-color: #627ea7;
  cursor: pointer;
}

.piik-btn-secondary {
  background-color: #627ea7;
  cursor: pointer;
}

.piik-modal-icon {
  color: #0d6efd;
}

.piik-modal-icon:hover {
  background-color: #0d6efd;
  color: #f0f0f0;
}

.piik-modal-bg {
  background-color: rgba(7, 7, 7, 0.5);
}

.piik-header-btn {
  color: #6c757d;
}

.piik-header-btn:hover {
  background-color: #0d6efd;
  color: white;
}

.piik-content-section {
  max-height: 50vh;
  overflow-y: scroll;
}

.piik-subscription-container {
  min-height: 50vh;
}

.piik-visual-section {
  max-height: 80vh;
  min-height: 80vh;
  overflow-y: scroll;
}

.piik-visual-section-fullscreen {
  max-height: 85vh;
  min-height: 85vh;
  overflow-y: scroll;
}

.piik-text-visual {
  font-size: 0.7rem;
}

.piik-create {
  min-height: 10vh;
}

.piik-create-chart select,
.piik-create-chart input,
.piik-create-chart textarea {
  font-size: 0.7rem;
}

.piik-calender-modal {
  max-height: 30vh;
  width: 100%;
  overflow-y: scroll;
}

.piik-full-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 150;
}

.piik-error-heading {
  font-size: 50px;
  font-family: Times, serif;
}

.piik-home-card {
  height: 85vh;
  overflow-y: scroll;
}

.piik-create-bar {
  width: 25%;
  max-width: 300px;
  min-width: 300px;
}

.piik-border-side-1 {
  border-left-style: solid;
  border-width: medium;
  border-color: #4f709c;
}

.piik-border-side-2 {
  border-left-style: solid;
  border-width: medium;
  border-color: #ffc436;
}

.piik-border-bottom-1 {
  border-bottom-style: solid;
  border-width: medium;
  border-color: #4f709c;
}

/* Need to Change */

.piik-border-bottom-2 {
  border-bottom-style: solid;
  border-width: medium;
  border-color: #ffc436;
}

.piik-plot-drag {
  cursor: default;
}

.piik-plot-drag:hover {
  cursor: ew-resize;
  border: 2px solid #5642d7;
}

.piik-icon:hover {
  color: blue;
}

.piik-video-container {
  max-width: 100%;
  margin: 0 auto;
}

.piik-video-container video {
  width: 95%;
}
